<script lang="ts" setup>
definePageMeta({
  middleware: 'noauth',
})

const route = useRoute()
const metaTitle = 'Continue to Epicareer'
const metaDescription = 'Find a better job opportunity. Login or create an account using email, Google, LinkedIn, or resume'
useHead({
  title: metaTitle,
  meta: [
    { property: 'og:title', content: metaTitle },
    { name: 'twitter:title', content: metaTitle },
    { name: 'description', content: metaDescription },
    { property: 'og:description', content: metaDescription },
    { name: 'twitter:description', content: metaDescription },
    { name: 'robots', content: 'follow, index' },
  ],
  link: [
    { rel: 'canonical', href: useSetSubdomain(useGetSubdomain()) + route.path },
  ],
})
</script>

<template>
  <div class="flex items-center py-10 sm:py-20">
    <ContinueForm class="mx-auto text-gray-900 px-4 sm:p-10 sm:shadow-md sm:rounded-md w-full max-w-md grid" />
  </div>
</template>
